import React from 'react';
import { reduxServices } from '../reducers/reduxServices';
import { useTypedSelector } from '../stateStore';

export interface IIfFeatureEnabledProps extends React.PropsWithChildren {
  name: string;
}

export const IfFeatureEnabled = ({
  children,
  name,
}: IIfFeatureEnabledProps) => {
  const featuresEnabled = useTypedSelector(
    reduxServices.setting.selectors.features,
  );

  return featuresEnabled?.[name] == 'true' ? <>{children}</> : <></>;
};

export default IfFeatureEnabled;
