import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Configuration, ErrorResponse, SalesApi } from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { SaleSliceState } from './saleState';

const GetSaleApi = () => {
  return new SalesApi(
    new Configuration(),
    configuration.SERVICES_API_BASE,
    axiosInstance,
  );
};
const findAll = createAsyncThunk(
  'sale/findAll',
  async (
    {
      beneficiaryTitleId,
      beneficiaryWorkspaceId,
      page,
      pageSize,
      sortBy,
    }: {
      beneficiaryTitleId?: string;
      beneficiaryWorkspaceId: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetSaleApi().saleControllerFindAll(
        beneficiaryWorkspaceId,
        beneficiaryTitleId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);
const saleState: SaleSliceState = {
  isSaleLoading: ApiLoadingStateEnum.idle,
  sales: [],
};
const saleSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isSaleLoading = ApiLoadingStateEnum.succeeded;
        const paginatedResponse = action.payload;

        state.sales = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isSaleLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isSaleLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: saleState,
  name: 'sale',
  reducers: {},
});
const saleThunk = {
  findAll,
};
const saleSelectors = {
  errorResponse: (state: RootState) => state.sales.errorResponse,
  isSaleLoading: (state: RootState) => state.sales.isSaleLoading,
  paginationMeta: (state: RootState) => state.sales.paginationMeta,
  sales: (state: RootState) => state.sales.sales,
};

export const saleService = {
  ...saleThunk,
  actions: saleSlice.actions,
  selectors: saleSelectors,
};
export default saleSlice.reducer;
