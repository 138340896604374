import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ILoaderProps extends ISpinnerProps {
  className?: string;
  loaderHeight?:
    | 'h-32'
    | 'h-40'
    | 'h-48'
    | 'h-64'
    | 'h-80'
    | 'h-96'
    | 'h-full'
    | 'h-screen';
}

const Loader = ({
  className,
  loaderHeight = 'h-screen',
  size = '2x',
}: ILoaderProps) => {
  return (
    <div className={className}>
      <div className={`absolute top-1/2  w-full text-center ${loaderHeight}`}>
        <Spinner size={size} />
      </div>
    </div>
  );
};

export interface ISpinnerProps {
  size?: '1x' | '2x';
}
export const Spinner = ({ size = '2x' }: ISpinnerProps) => {
  return <FontAwesomeIcon icon={faCircleNotch} size={size} spin />;
};

const LoaderV2 = ({ loaderHeight = 'h-screen', size = '2x' }: ILoaderProps) => {
  return (
    <div className={`absolute w-full mx-[50%] ${loaderHeight}`}>
      <Spinner size={size} />
    </div>
  );
};

const FlexLoader = ({ className, loaderHeight, size = '2x' }: ILoaderProps) => {
  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center justify-center text-center',
        loaderHeight,
        className,
      )}
    >
      <Spinner size={size} />
    </div>
  );
};

const Overlay = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="absolute inset-0 z-10 bg-white opacity-75"></div>
      <FlexLoader className="absolute inset-0 z-50 bg-transparent" />
    </div>
  );
};

export const AppLoader = Object.assign(LoaderV2, {
  AbsoluteLoader: Loader,
  FlexLoader,
  Overlay,
  Spinner,
});

export default AppLoader;
