import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { useState } from 'react';
import { Button, Divider } from 'react-daisyui';
import { Link, useNavigate } from 'react-router-dom';
import AppAvatar from '../../components/AppAvatar';
import { userService } from '../../reducers/permissions/userSlice';
import { useTypedSelector } from '../../stateStore';
import AppDropdown from '../AppDropdown';
import { AppModal } from '../AppModal';
import IfFeatureEnabled from '../IfFeatureEnabled';
import ProtectElement from '../ProtectElement';
import './UserProfileMini.css';

const UserProfileMini = () => {
  const navigate = useNavigate();
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);

  const userMe = useTypedSelector(userService.selectors.userMe);

  if (userMe == null) return <></>;

  return (
    <>
      <AppDropdown
        buttonClassName="w-full p-[4px] h-[50px] gap-1.5 btn-circle btn-accent"
        toggleContent={
          <>
            <AppAvatar imgUri={userMe.imageUri} shape="circle" size="xs" />
            <span className="sr-only">{userMe?.firstName}&rsquo;s profile</span>
          </>
        }
        toggleIconElement={<FontAwesomeIcon icon={faAngleDown} />}
      >
        <li role="menuitem">
          <Link to="/profile/my-profile">My Profile</Link>
        </li>
        <IfFeatureEnabled name="notificationsMenuItem">
          <ProtectElement
            permissionRequired={[PermissionEnum.ViewNotificationFeed]}
          >
            <li role="menuitem">
              <Link to="/profile/notifications">Notifications</Link>
            </li>
          </ProtectElement>
        </IfFeatureEnabled>
        <Divider className="h-px" />
        <li role="menuitem">
          <a
            href="https://knowledge.screenrights.org/en"
            rel="noreferrer"
            target="_blank"
          >
            Help
          </a>
        </li>
        <li role="menuitem">
          <Link target="_blank" to="/terms">
            Terms and Conditions
          </Link>
        </li>
        <Divider className="h-px" />
        <li role="menuitem">
          <Button
            className="leading-normal no-underline link hover:no-underline color-secondary"
            variant="link"
            onClick={() => setIsLogOutModalOpen(true)}
          >
            <span className="relative top-[3px]">Log out</span>
          </Button>
        </li>
      </AppDropdown>
      <AppModal
        title="Are you sure you want to log out?"
        hasCancelButton={true}
        isOpen={isLogOutModalOpen}
        okButtonText="Log out"
        onCancelClick={() => setIsLogOutModalOpen(false)}
        onOkClick={() => navigate('/logout')}
      >
        <></>
      </AppModal>
    </>
  );
};

export default UserProfileMini;
