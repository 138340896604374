import React from 'react';
import { globalService } from '../reducers/globalState/globalStateSlice';
import { reduxServices } from '../reducers/reduxServices';
import { useAppDispatch, useTypedSelector } from '../stateStore';
import { AppModal } from './AppModal';

export interface IErrorModalProps {
  isOpen?: boolean;
  noInternet?: boolean;
  onDismiss?: (value: boolean) => void;
  responseError?: boolean;
}

export const ErrorModal = ({
  isOpen,
  noInternet,
  onDismiss,
}: IErrorModalProps) => {
  const dispatch = useAppDispatch();
  const currentError = useTypedSelector(globalService.selectors.currentError);
  const networkError = () =>
    currentError?.code === 'ERR_NETWORK' || noInternet || !navigator.onLine;

  const handleDismissClick = () => {
    dispatch(reduxServices.globalState.actions.setShowErrorModal(false));
    onDismiss?.(false);
  };

  return (
    <AppModal
      title={
        networkError()
          ? 'There is no internet connection'
          : 'Sorry, something went wrong'
      }
      hasActionButtons={true}
      hasCancelButton={false}
      isOpen={isOpen}
      onCancelClick={handleDismissClick}
      onOkClick={handleDismissClick}
    >
      {networkError() ? (
        <p>Please check the connection and try again.</p>
      ) : (
        <p>
          There&rsquo;s an error on our end.
          <br />
          Try again later.
        </p>
      )}
    </AppModal>
  );
};

export default ErrorModal;
