import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Button } from 'react-daisyui';
import { twMerge } from 'tailwind-merge';
import { GenericMap, StringMap } from '../types/commonTypes';
import { ThemeColor } from '../utils/helpers/common';

type AppButtonWidth =
  | '1/2'
  | '1/3'
  | '1/6'
  | '1x-wide'
  | '2x-wide'
  | '3x-wide'
  | '4x-wide'
  | 'auto'
  | 'default'
  | 'fit'
  | 'full'
  | 'w-8'
  | 'wide'
  | 'x-wide';

type AppButtonColor = 'accent' | 'danger' | 'primary' | 'secondary';

export interface IAppButtonProps {
  children: ReactNode;
  className?: string;
  color?: AppButtonColor;
  disabled?: boolean;
  endIcon?: ReactNode;
  isIconOnMobile?: boolean;
  loading?: boolean;
  onClick?: () => void;
  shape?: 'rounded' | 'square';
  size?: 'lg' | 'md' | 'sm' | 'xs';
  startIcon?: ReactNode;
  type?: 'button' | 'submit';
  variant?: 'link' | 'link-inline' | 'outline';
  width?: AppButtonWidth;
}

const AppButtonColors: GenericMap<AppButtonColor, ThemeColor> = {
  accent: 'accent',
  danger: 'error',
  primary: 'primary',
  secondary: 'ghost',
};

const AppButtonShapes: StringMap<string> = {
  rounded: 'rounded-full drop-shadow-lg',
  square: '',
};

const AppButtonWidths: GenericMap<AppButtonWidth, string> = {
  '1/2': 'w-1/2',
  '1/3': 'w-1/3',
  '1/6': 'w-24 md:w-1/6',
  '1x-wide': 'w-60',
  '2x-wide': 'w-full md:w-64',
  '3x-wide': 'w-full md:w-80',
  '4x-wide': 'w-full md:w-96',
  auto: 'w-auto',
  default: 'w-32',
  fit: 'w-fit',
  full: 'w-full',
  'w-8': 'w-8',
  wide: 'w-40 md:w-48',
  'x-wide': 'w-52',
};

export const AppButton = ({
  children,
  className,
  color = 'primary',
  disabled = false,
  endIcon,
  isIconOnMobile,
  loading = false,
  onClick,
  shape = 'square',
  size = 'sm',
  startIcon,
  type = 'button',
  variant,
  width = 'default',
}: IAppButtonProps) => {
  const colorClass = AppButtonColors[color];
  const shapeClass = AppButtonShapes[shape];
  const widthClass = AppButtonWidths[width];

  const classes = twMerge(
    'text-base',
    shapeClass,
    widthClass,
    className,
    clsx({
      'btn-link-inline': variant === 'link-inline',
      'px-1.5 sm:px-3': (loading && width === 'w-8') || isIconOnMobile,
    }),
  );

  return (
    <Button
      className={classes}
      color={colorClass}
      disabled={disabled}
      endIcon={endIcon ?? ''}
      loading={loading}
      size={size}
      startIcon={startIcon ?? ''}
      type={type}
      variant={variant === 'link-inline' ? 'link' : variant}
      onClick={() => onClick?.()}
    >
      <span
        className={twMerge(
          'text-sm md:text-base contents',
          clsx({
            'hidden sm:contents': isIconOnMobile,
            'md:text-sm': loading,
          }),
        )}
      >
        {children}
      </span>
    </Button>
  );
};
