import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  AcceptInvitationDto,
  Configuration,
  CreateInvitationDto,
  ErrorResponse,
  InvitationsApi,
  PaginatedInvitationsDto,
  UpdateInvitationDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { InvitationSliceState } from './invitationSliceState';

// Function for creating an instance of the InvitationsApi class
const GetInvitationsApi = () => {
  return new InvitationsApi(
    new Configuration(),
    configuration.PERMISSIONS_API_BASE,
    axiosInstance,
  );
};

const checkExisting = createAsyncThunk(
  'invitation/checkExisting',
  async (
    {
      emailAddress,
      workspaceId,
    }: { emailAddress: string; workspaceId: string },
    thunkApi,
  ) => {
    try {
      const response =
        await GetInvitationsApi().invitationControllerCheckExisting(
          workspaceId,
          emailAddress,
        );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const create = createAsyncThunk(
  'invitation/create',
  async (payload: CreateInvitationDto, thunkApi) => {
    try {
      const response = await GetInvitationsApi().invitationControllerCreate(
        payload,
        invalidateCacheRequestConfig('invitation'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const resend = createAsyncThunk(
  'invitation/resend',
  async (
    {
      id,
      updateInvitationDto,
    }: { id: string; updateInvitationDto: UpdateInvitationDto },
    thunkApi,
  ) => {
    try {
      const response = await GetInvitationsApi().invitationControllerResend(
        id,
        updateInvitationDto,
        invalidateCacheRequestConfig('invitation'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAll = createAsyncThunk(
  'invitation/findAll',
  async (
    {
      name,
      page = 1,
      pageSize = 5,
      sortBy = 'updated:desc',
      workspaceId,
    }: {
      name?: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceId?: string;
    },
    thunkApi,
  ) => {
    try {
      const response = await GetInvitationsApi().invitationControllerFindAllV2(
        name,
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const remove = createAsyncThunk(
  'invitation/remove',
  async (invitationId: string, thunkApi) => {
    try {
      const response = await GetInvitationsApi().invitationControllerRemove(
        invitationId,
        invalidateCacheRequestConfig('invitation'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'invitation/update',
  async (
    {
      id,
      updateInvitationDto,
    }: { id: string; updateInvitationDto: UpdateInvitationDto },
    thunkApi,
  ) => {
    try {
      const response = await GetInvitationsApi().invitationControllerUpdate(
        id,
        updateInvitationDto,
        invalidateCacheRequestConfig('invitation'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const verify = createAsyncThunk(
  'invitation/verify',
  async (code: string, thunkApi) => {
    try {
      const response =
        await GetInvitationsApi().invitationControllerFindAndVerify(code);

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const accept = createAsyncThunk(
  'invitation/accept',
  async (acceptInvitationDto: AcceptInvitationDto, thunkApi) => {
    try {
      const response =
        await GetInvitationsApi().invitationControllerAcceptInvitation(
          acceptInvitationDto,
          invalidateCacheRequestConfig('user/me'),
        );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialInvitationState: InvitationSliceState = {
  isCreating: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
  isRemoving: ApiLoadingStateEnum.idle,
  isResending: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
  isVerifying: ApiLoadingStateEnum.idle,
  numInvitationsSent: null,
};

const invitationsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(create.fulfilled, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.succeeded;
        state.invitation = action.payload;
      })
      .addCase(create.rejected, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.failed;
        state.createError = action.payload as ErrorResponse;
      })

      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as PaginatedInvitationsDto;

        state.invitations = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.loadError = action.payload as ErrorResponse;
      })

      // Remove
      .addCase(remove.fulfilled, (state) => {
        state.isRemoving = ApiLoadingStateEnum.succeeded;
      })
      .addCase(remove.pending, (state) => {
        state.isRemoving = ApiLoadingStateEnum.loading;
      })
      .addCase(remove.rejected, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.failed;
        state.removeError = action.payload as ErrorResponse;
      })

      // Update
      .addCase(update.fulfilled, (state) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(update.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.updateError = action.payload as ErrorResponse;
      })

      // Resend
      .addCase(resend.fulfilled, (state) => {
        state.isResending = ApiLoadingStateEnum.succeeded;
      })
      .addCase(resend.pending, (state) => {
        state.isResending = ApiLoadingStateEnum.loading;
      })
      .addCase(resend.rejected, (state, action) => {
        state.isResending = ApiLoadingStateEnum.failed;
        state.resendError = action.payload as ErrorResponse;
      })

      // Verify
      .addCase(verify.fulfilled, (state, action) => {
        state.isVerifying = ApiLoadingStateEnum.succeeded;
        state.invitation = action.payload;
      })
      .addCase(verify.pending, (state) => {
        state.isVerifying = ApiLoadingStateEnum.loading;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isVerifying = ApiLoadingStateEnum.failed;
        state.verifyError = action.payload as ErrorResponse;
      })

      // Accept
      .addCase(accept.fulfilled, (state) => {
        state.isCreating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(accept.rejected, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.failed;
        state.createError = action.payload as ErrorResponse;
      })

      // Note: if pending action creator is more than one it should here
      // IsCreating pending
      .addMatcher(isAnyOf(accept.pending, create.pending), (state) => {
        state.isCreating = ApiLoadingStateEnum.loading;
      });
  },
  initialState: initialInvitationState,
  name: 'invitations',
  reducers: {
    resetErrors: (state) => {
      return {
        ...state,
        createError: initialInvitationState.createError,
        resendError: initialInvitationState.resendError,
      };
    },
    resetLoadingState: (state) => {
      // Add more loading state here
      return {
        ...state,
        isCreating: ApiLoadingStateEnum.idle,
        isRemoving: ApiLoadingStateEnum.idle,
        isResending: ApiLoadingStateEnum.idle,
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
    setNumInvitationsSent: (state, action) => {
      state.numInvitationsSent = action.payload;
    },
  },
});

const invitationThunk = {
  accept,
  checkExisting,
  create,
  findAll,
  remove,
  resend,
  update,
  verify,
};

const invitationSelectors = {
  createError: (state: RootState) => state.invitations.createError,
  invitation: (state: RootState) => state.invitations.invitation,
  invitations: (state: RootState) => state.invitations.invitations,
  isCreating: (state: RootState) => state.invitations.isCreating,
  isLoading: (state: RootState) => state.invitations.isLoading,
  isRemoving: (state: RootState) => state.invitations.isRemoving,
  isResending: (state: RootState) => state.invitations.isResending,
  isUpdating: (state: RootState) => state.invitations.isUpdating,
  loadError: (state: RootState) => state.invitations.loadError,
  numInvitationsSent: (state: RootState) =>
    state.invitations.numInvitationsSent,
  paginationMeta: (state: RootState) => state.invitations.paginationMeta,
  removeError: (state: RootState) => state.invitations.removeError,
  resendError: (state: RootState) => state.invitations.resendError,
  updateError: (state: RootState) => state.invitations.updateError,
  verifyError: (state: RootState) => state.invitations.verifyError,
};

export const invitationService = {
  ...invitationThunk,
  actions: invitationsSlice.actions,
  selectors: invitationSelectors,
};

export default invitationsSlice.reducer;
