import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const EmbedRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(lazy(() => import('../layout/EmbedLayout'))),
    loader: () => {
      return {
        permissionRequired: [PermissionEnum.ViewWorkspace],
      };
    },
    children: [
      {
        path: 'manage-workspace',
        children: [
          {
            path: 'details',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Workspace/ManageWorkspace/Details')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditWorkspaceDetails],
            }),
          },
          {
            path: 'bank-account',
            element: ProtectLazyLoadRoute(
              lazy(
                () =>
                  import('../pages/Workspace/ManageWorkspace/BankAccountV2'),
              ),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditBankDetails],
            }),
          },
        ],
      },
    ],
  },
];
