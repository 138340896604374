import React, { useEffect } from 'react';
import { Breadcrumbs } from 'react-daisyui';
import { Helmet } from 'react-helmet';
import { useMatches } from 'react-router-dom';
import { TRouteLoaderData } from '../../types/commonTypes';

interface IAppHeadingWithBreadcrumbsProps {
  hasBreadcrumbs?: boolean;
  initialCrumb?: string;
  onResetWorkspace?: () => void;
  userType?: string;
  workspaceName?: string;
}

const AppHeadingWithBreadcrumbs = ({
  hasBreadcrumbs,
  initialCrumb,
  onResetWorkspace,
  userType,
  workspaceName,
}: IAppHeadingWithBreadcrumbsProps) => {
  const matches = useMatches();

  // Create a reversed copy of the matches array for use setting page title
  const pageTitleParts = [
    ...matches
      .map((match) => (match.data as TRouteLoaderData)?.title)
      .reverse(),
    initialCrumb,
    'Screenrights',
  ].filter((title) => !!title);

  // Filter the matches to those which contain loader data.title property
  const crumbs = matches.filter((match) => {
    const data = match.data as TRouteLoaderData;

    if (data && data.title) return true;
  });

  useEffect(() => {
    if (!matches[matches.length - 1].params.workspaceId) onResetWorkspace?.();
  }, [matches, onResetWorkspace]);

  // Pop the last crumb off the array to use as the main heading title
  const title = crumbs.pop();

  // Pop again to remove the current page
  // Base from the number 10 comment
  // https://screenrights.atlassian.net/browse/APP-408?focusedCommentId=22724
  crumbs.pop();

  return (
    <>
      <Helmet>
        <title>{pageTitleParts.join(' | ')}</title>
      </Helmet>
      <div className="flex items-center gap-5">
        <div className="flex flex-col items-start gap-0.5 flex-[1_0_0]">
          <div className="font-sans text-lg font-bold !leading-normal text-white truncate xs:text-xl sm:text-2xl text-nowrap">
            {(title?.data as TRouteLoaderData)?.title}
          </div>
          {hasBreadcrumbs && (
            <Breadcrumbs className="hidden p-0 text-sm !leading-normal font-normal text-white opacity-50 sm:inline-block">
              <>
                {initialCrumb && (
                  <Breadcrumbs.Item>{initialCrumb}</Breadcrumbs.Item>
                )}
                {workspaceName && userType == 'internal' ? (
                  <Breadcrumbs.Item>{workspaceName}</Breadcrumbs.Item>
                ) : (
                  crumbs.map((crumb) => (
                    <Breadcrumbs.Item key={crumb.id}>
                      {(crumb.data as TRouteLoaderData)?.title}
                    </Breadcrumbs.Item>
                  ))
                )}
              </>
            </Breadcrumbs>
          )}
        </div>
      </div>
    </>
  );
};

export default AppHeadingWithBreadcrumbs;
