import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stateStore';
import { GlobalStateSlice } from '../../types/globalTypes';

export const initialGlobalState: GlobalStateSlice = {
  currentError: {},
  showErrorModal: false,
  showErrorPage: false,
};

const globalStateSlice = createSlice({
  initialState: initialGlobalState,
  name: 'globalState',
  reducers: {
    scrollToTop: (state) => {
      state.scrollToTop = new Date().toISOString();
    },
    setCurrentError: (state, action) => {
      state.currentError = action.payload;
    },
    setShowErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },
    setShowErrorPage: (state, action) => {
      state.showErrorPage = action.payload;
    },
  },
});

const globalSelectors = {
  currentError: (state: RootState) => state.globalReducer.currentError,
  scrollToTop: (state: RootState) => state.globalReducer.scrollToTop,
  showErrorModal: (state: RootState) => state.globalReducer.showErrorModal,
  showErrorPage: (state: RootState) => state.globalReducer.showErrorPage,
};

export const globalService = {
  actions: globalStateSlice.actions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) =>
    Promise.resolve(''),
  selectors: globalSelectors,
};

export default globalStateSlice.reducer;
