import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AuthorisePayoutsDto,
  Configuration,
  ErrorResponse,
  InitiatePayoutsDto,
  PaginatedPayoutsDto,
  PayoutStatusEnum,
  PayoutsApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { PayoutSliceState } from './payoutSliceState';

const GetPayoutApi = () => {
  return new PayoutsApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};

const initiatePayout = createAsyncThunk(
  'payout/initiate',
  async (
    {
      initiatePayoutsDto,
    }: {
      initiatePayoutsDto: InitiatePayoutsDto;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPayoutApi().payoutControllerInitiate(
        initiatePayoutsDto,
        invalidateCacheRequestConfig('payout'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const authorisePayout = createAsyncThunk(
  'payout/authorise',
  async (
    {
      authorisePayoutsDto,
    }: {
      authorisePayoutsDto: AuthorisePayoutsDto;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPayoutApi().payoutControllerAuthorise(
        authorisePayoutsDto,
        invalidateCacheRequestConfig('payout'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAll = createAsyncThunk(
  'payout/findAll',
  async (
    {
      beneficiaryWorkspaceId,
      name,
      page,
      pageSize,
      paymentDateFrom,
      paymentDateTo,
      payoutGenerationTimestampFrom,
      payoutGenerationTimestampTo,
      sortBy,
      status,
    }: {
      beneficiaryWorkspaceId?: string;
      name?: string;
      page?: number;
      pageSize?: number;
      paymentDateFrom?: string;
      paymentDateTo?: string;
      payoutGenerationTimestampFrom?: string;
      payoutGenerationTimestampTo?: string;
      sortBy?: string;
      status?: PayoutStatusEnum[];
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPayoutApi().payoutControllerFindAll(
        name,
        status,
        beneficiaryWorkspaceId,
        paymentDateFrom,
        paymentDateTo,
        payoutGenerationTimestampFrom,
        payoutGenerationTimestampTo,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialPayoutState: PayoutSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  isSubmitting: ApiLoadingStateEnum.idle,
  payouts: [],
};

const payoutsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as any as PaginatedPayoutsDto;

        state.payouts = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      .addCase(initiatePayout.fulfilled, (state) => {
        state.isSubmitting = ApiLoadingStateEnum.succeeded;
      })
      .addCase(initiatePayout.pending, (state) => {
        state.isSubmitting = ApiLoadingStateEnum.loading;
      })
      .addCase(initiatePayout.rejected, (state, action) => {
        state.isSubmitting = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      .addCase(authorisePayout.fulfilled, (state) => {
        state.isSubmitting = ApiLoadingStateEnum.succeeded;
      })
      .addCase(authorisePayout.pending, (state) => {
        state.isSubmitting = ApiLoadingStateEnum.loading;
      })
      .addCase(authorisePayout.rejected, (state, action) => {
        state.isSubmitting = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialPayoutState,
  name: 'payouts',
  reducers: {},
});

const payoutThunk = {
  authorisePayout,
  findAll,
  initiatePayout,
};

const payoutSelectors = {
  errorResponse: (state: RootState) => state.payouts.errorResponse,
  isLoading: (state: RootState) => state.payouts.isLoading,
  isSubmitting: (state: RootState) => state.payouts.isSubmitting,
  paginationMeta: (state: RootState) => state.payouts.paginationMeta,
  payouts: (state: RootState) => state.payouts.payouts,
};

export const payoutService = {
  ...payoutThunk,
  actions: payoutsSlice.actions,
  selectors: payoutSelectors,
};

export default payoutsSlice.reducer;
