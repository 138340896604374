import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CascadesApi,
  Configuration,
  ErrorResponse,
  PaginatedCascadesDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { CascadeSliceState } from './cascadesState';

const GetCascadeApi = () => {
  return new CascadesApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};
const findAllCascade = createAsyncThunk(
  'cascade/findAllCascade',
  async (
    {
      beneficiaryTitleId,
      beneficiaryWorkspaceId,
      page,
      pageSize,
      sortBy,
    }: {
      beneficiaryTitleId?: string;
      beneficiaryWorkspaceId: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetCascadeApi().cascadeControllerFindAll(
        beneficiaryWorkspaceId,
        beneficiaryTitleId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);
const cascadeState: CascadeSliceState = {
  cascades: [],
  isCascadesLoading: ApiLoadingStateEnum.idle,
};
const cascadeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAllCascade
      .addCase(findAllCascade.fulfilled, (state, action) => {
        state.isCascadesLoading = ApiLoadingStateEnum.succeeded;
        const paginatedResponse = action.payload as PaginatedCascadesDto;

        state.cascades = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAllCascade.pending, (state) => {
        state.isCascadesLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllCascade.rejected, (state, action) => {
        state.isCascadesLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: cascadeState,
  name: 'cascade',
  reducers: {},
});
const cascadeThunk = {
  findAllCascade,
};
const cascadeSelectors = {
  cascades: (state: RootState) => state.cascades.cascades,
  errorResponse: (state: RootState) => state.cascades.errorResponse,
  isCascadesLoading: (state: RootState) => state.cascades.isCascadesLoading,
  paginationMeta: (state: RootState) => state.cascades.paginationMeta,
};

export const cascadeService = {
  ...cascadeThunk,
  actions: cascadeSlice.actions,
  selectors: cascadeSelectors,
};
export default cascadeSlice.reducer;
