import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AirwallexApi,
  AirwallexBalance,
  Configuration,
  ErrorResponse,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { BalanceSliceState } from './airwallexSliceState';

const GetAirwallexApi = () => {
  return new AirwallexApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};

const findAllBalances = createAsyncThunk(
  'airwallex/findAllBalances',
  async (
    {
      currency,
    }: {
      currency: Array<string>;
    },
    thunkApi,
  ) => {
    try {
      const result =
        await GetAirwallexApi().airwallexControllerGetCurrentBalances(currency);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialAirwallexState: BalanceSliceState = {
  balances: [],
  isLoading: ApiLoadingStateEnum.idle,
};

const airwallexSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAllBalances
      .addCase(findAllBalances.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        state.balances = action.payload as AirwallexBalance[];
      })
      .addCase(findAllBalances.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllBalances.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialAirwallexState,
  name: 'airwallex',
  reducers: {
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialAirwallexState.errorResponse,
      };
    },
    setDownloadErrorResponse: (state, action) => {
      return {
        ...state,
        errorResponse: action.payload,
      };
    },
  },
});

const airwallexThunk = {
  findAllBalances,
};

const airwallexSelectors = {
  balances: (state: RootState) => state.airwallex.balances,
  errorResponse: (state: RootState) => state.airwallex.errorResponse,
  isLoading: (state: RootState) => state.airwallex.isLoading,
};

export const airwallexService = {
  ...airwallexThunk,
  actions: airwallexSlice.actions,
  selectors: airwallexSelectors,
};

export default airwallexSlice.reducer;
