import {
  faCircleQuestion,
  faFaceSadCry,
} from '@fortawesome/free-regular-svg-icons';
import { faBan, faSlash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { globalService } from '../reducers/globalState/globalStateSlice';
import { useTypedSelector } from '../stateStore';

export interface IErrorPageProps {
  genericError?: boolean;
  noInternet?: boolean;
  notAuthorized?: boolean;
  notFound?: boolean;
}

export const ErrorPage = ({
  genericError,
  noInternet,
  notAuthorized,
  notFound,
}: IErrorPageProps) => {
  const currentError = useTypedSelector(globalService.selectors.currentError);
  const networkError = () => noInternet || currentError?.code === 'ERR_NETWORK';

  const handleRefreshClick = () => {
    window.location.reload();
  };

  if (genericError || notAuthorized || notFound) {
    const messageText: string = notAuthorized
      ? 'You do not have access to the page you requested.'
      : notFound
      ? 'We cannot find the page you are looking for.'
      : 'Something went wrong.';

    const icon = notAuthorized
      ? faBan
      : notFound
      ? faCircleQuestion
      : faFaceSadCry;

    return (
      <div className="flex flex-col items-center justify-center text-center gap-8 p-10">
        <FontAwesomeIcon
          className="mb-5 text-read-only-bg"
          icon={icon}
          size="7x"
        />
        <p className="text-xl">{messageText}</p>
        <p className="text-base">
          Please try{' '}
          <Button
            className="btn-link-inline font-semibold text-sr-black text-base"
            variant="link"
            onClick={handleRefreshClick}
          >
            refreshing
          </Button>{' '}
          the page, or{' '}
          <Link className="font-semibold underline" to="/dashboard">
            return to the homepage
          </Link>
          .
        </p>
        <p className="text-base">
          If the problem persists, please report it to{' '}
          <a
            className="font-semibold underline"
            href="mailto:support@screenrights.org"
          >
            support@screenrights.org
          </a>
          .
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center text-center gap-8 p-10">
      {networkError() ? (
        <div className="fa-layers mb-5 h-[7em] w-[10em]">
          <FontAwesomeIcon
            className="text-read-only-bg"
            icon={faWifi}
            size="7x"
          />
          <FontAwesomeIcon
            className="text-read-only-bg"
            flip="vertical"
            icon={faSlash}
            size="7x"
          />
        </div>
      ) : (
        <FontAwesomeIcon
          className="mb-5 text-read-only-bg text-left"
          icon={faFaceSadCry}
          size="7x"
        />
      )}

      <p className="text-xl">
        {networkError()
          ? 'There is no internet connection.'
          : 'There’s an error on our end. We cannot complete your request.'}
      </p>
      <p className="text-base">
        Try{' '}
        <Button
          className="btn-link-inline font-semibold text-sr-black text-base"
          variant="link"
          onClick={handleRefreshClick}
        >
          refreshing
        </Button>{' '}
        the page.
      </p>
      <p className="text-base">
        If the problem persists, please report it to{' '}
        <a
          className="font-semibold underline"
          href="mailto:support@screenrights.org"
        >
          support@screenrights.org
        </a>
        .
      </p>
    </div>
  );
};

export default ErrorPage;
