import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { environmentName } from '../configuration';
import { capitalizeWords } from '../utils/helpers/common';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const AdminRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(lazy(() => import('../layout/AdminLayout'))),
    children: [
      {
        path: 'docs',
        element: <Navigate replace to="../swagger" />,
        children: [{ path: ':selectedApi', element: <></> }],
      },
      {
        path: 'swagger',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Admin/Swagger')),
        ),
        loader: () => {
          const data = {
            permissionRequired: [],
            title: 'Swagger',
          };

          if (environmentName !== 'production') {
            // In pre-production, allow authenticated users to access the docs page.
            // This is not a risk because the API calls still require a valid authorization token
            // And authorization happens on the server side
            return data;
          }

          return {
            ...data,
            permissionRequired: [
              PermissionEnum.EditDocuments,
              PermissionEnum.EditPermissions,
              PermissionEnum.ViewActivities,
            ],
          };
        },
        children: [
          {
            path: ':selectedApi',
            element: <></>,
            loader: ({ params }) => {
              return {
                title: `${capitalizeWords(
                  params.selectedApi || 'API',
                )} Service`,
              };
            },
          },
        ],
      },
    ],
  },
];
