import React, { ReactNode, useEffect } from 'react';
import { Navbar } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { userService } from '../../reducers/permissions/userSlice';
import { workspaceService } from '../../reducers/workspaces/workspaceSlice';
import { useAppDispatch, useTypedSelector } from '../../stateStore';
import './AppSidebar.css';
import { WorkspaceSidebarIcons } from './WorkspaceSidebarIcons';

export interface IAppSidebarProps {
  children?: ReactNode;
  onClick?: () => void;
}

const AppSidebar = ({ children, onClick }: IAppSidebarProps) => {
  const dispatch = useAppDispatch();
  const hasSubMenu = !!children;

  const currentUser = useTypedSelector(userService.selectors.userMe);

  useEffect(() => {
    if (!currentUser) return;

    return () => {
      dispatch(workspaceService.actions.reset());
    };
  }, [currentUser, dispatch]);

  return (
    <Navbar
      id="mainNavbar"
      className={`h-screen p-0 bg-transparent text-sr-white lg:bg-sr-white ${
        hasSubMenu ? 'w-64' : 'w-[100px]'
      }`}
      onClick={() => onClick?.()}
    >
      <div className="inline-flex flex-col items-start flex-shrink-0 w-full h-full px-2 py-5 gap-7 bg-sr-black">
        {!location.pathname.includes('dashboard') ? (
          <div className="flex flex-col items-center self-stretch justify-center gap-3">
            <Link to="/dashboard">
              <div color="ghost">
                <img
                  alt="Screenrights"
                  className="h-12 w-[54.085px] mx-auto"
                  src="/logo_sr.svg"
                />
              </div>
            </Link>
          </div>
        ) : (
          <div
            className="flex flex-col items-center self-stretch justify-center gap-3"
            color="ghost"
          >
            <img
              alt="Screenrights"
              className="h-12 w-[54.085px] mx-auto"
              src="/logo_sr.svg"
            />
          </div>
        )}
        {children ? children : <WorkspaceSidebarIcons />}
      </div>
    </Navbar>
  );
};

export default AppSidebar;
