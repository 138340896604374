import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  PaginatedTitlesDto,
  TitlesApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { TitleSliceState } from './titleSliceState';

// Function for creating an instance of the UsersApi class
const GetTitlesApi = () => {
  return new TitlesApi(
    new Configuration(),
    configuration.TITLES_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk(
  'title/findAll',
  async (workspaceId: string, thunkApi) => {
    try {
      const result = await GetTitlesApi().titleControllerFindAll(workspaceId);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAllPaginated = createAsyncThunk(
  'title/findAllPaginated',
  async (
    {
      name,
      page,
      pageSize,
      sortBy,
      workspaceId,
    }: {
      name?: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceId?: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetTitlesApi().titleControllerFindAllV2(
        name,
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findOne = createAsyncThunk(
  'title/findOne',
  async (id: string, thunkApi) => {
    try {
      const result = await GetTitlesApi().titleControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialTitleState: TitleSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  isTitleLoading: ApiLoadingStateEnum.idle,
  titles: [],
};

const titlesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindOne
      .addCase(findOne.fulfilled, (state, action) => {
        state.isTitleLoading = ApiLoadingStateEnum.succeeded;
        state.title = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isTitleLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isTitleLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.titles = action.payload as any;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAllPaginated
      .addCase(findAllPaginated.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as PaginatedTitlesDto;

        state.titles = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAllPaginated.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllPaginated.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialTitleState,
  name: 'title',
  reducers: {
    reset: () => {
      return {
        ...initialTitleState,
      };
    },
  },
});

const titleThunk = {
  findAll,
  findAllPaginated,
  findOne,
};

const titleSelectors = {
  errorResponse: (state: RootState) => state.titles.errorResponse,
  isLoading: (state: RootState) => state.titles.isLoading,
  isTitleLoading: (state: RootState) => state.titles.isTitleLoading,
  paginationMeta: (state: RootState) => state.titles.paginationMeta,
  title: (state: RootState) => state.titles.title,
  titles: (state: RootState) => state.titles.titles,
};

export const titleService = {
  ...titleThunk,
  actions: titlesSlice.actions,
  selectors: titleSelectors,
};

export default titlesSlice.reducer;
