import {
  EntityTypeEnum,
  GstStatusEnum,
  PermissionEnum,
} from '@sr-sdks/api-sdk-axios';

export const ManageWorkspacePermissions = [
  PermissionEnum.EditWorkspaceDetails,
  PermissionEnum.EditBankDetails,
  PermissionEnum.EditTeammates,
];

export type WorkspaceOutletContext = { workspaceId?: string };

export interface IBusinessDetailsFields {
  businessNumber: string;
  businessNumberCountry: string;
  businessNumberVerified: boolean;
  entityName: string;
  entityType: EntityTypeEnum;
  gstStatus: GstStatusEnum;
  representsClients: string;
  taxResidency: string;
  workspaceName: string;
}

export interface IWorkspaceDetailsFields
  extends IBusinessDetailsFields,
    IContactDetailsFields {
  canProceed: boolean;
  workspaceType: string;
}

export interface IAddressFields {
  address: string;
  addressAutocomplete: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}

export interface IContactDetailsFields extends IAddressFields {
  phone: string;
  website: string;
}

// TODO: Update this when ActivityStatement is available in SR SDK
export type ActivityStatement = {
  _id: string;
  cascadeDate: string;
  currency: string;
  disbursementAmount: string;
  status: ActivityStatementEnum;
  title: string;
};

export enum ActivityStatementEnum {
  Final = 'Final',
  Provisional = 'Provisional',
}
