import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  BankDetail,
  BankDetailsApi,
  Configuration,
  CreateBankDetailDto,
  ErrorResponse,
  PaginatedBankDetailsDto,
  UpdateBankDetailDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { BankDetailsSliceState } from './bankDetailsSliceState';

// Function for creating an instance of the InvitationsApi class
const GetBankDetailsApi = () => {
  return new BankDetailsApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};

const create = createAsyncThunk(
  'bank-detail/create',
  async (payload: CreateBankDetailDto, thunkApi) => {
    try {
      const response = await GetBankDetailsApi().bankDetailsControllerCreate(
        payload,
        invalidateCacheRequestConfig('bank-details'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAll = createAsyncThunk(
  'bank-detail/findAll',
  async (
    {
      page = 1,
      pageSize = 100,
      sortBy,
      workspaceId,
    }: {
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceId: string;
    },
    thunkApi,
  ) => {
    try {
      const response = await GetBankDetailsApi().bankDetailsControllerFindAll(
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findOne = createAsyncThunk(
  'bank-detail/findOne',
  async (id: string, thunkApi) => {
    try {
      const response = await GetBankDetailsApi().bankDetailsControllerFindOne(
        id,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'bank-detail/update',
  async (
    {
      id,
      updateBankDetailDto,
    }: { id: string; updateBankDetailDto: UpdateBankDetailDto },
    thunkApi,
  ) => {
    try {
      const result = await GetBankDetailsApi().bankDetailsControllerUpdate(
        id,
        updateBankDetailDto,
        invalidateCacheRequestConfig('bank-details'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const remove = createAsyncThunk(
  'bank-detail/remove',
  async (id: string, thunkApi) => {
    try {
      const response = await GetBankDetailsApi().bankDetailsControllerRemove(
        id,
        invalidateCacheRequestConfig('bank-details'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

export const initialBankDetailsState: BankDetailsSliceState = {
  bankDetails: [],
  isCreating: ApiLoadingStateEnum.idle,
  isFindOneLoading: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
  isRemoving: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
};

const bankDetailSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(create.fulfilled, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.succeeded;
        state.bankDetail = action.payload;
      })
      .addCase(create.pending, (state) => {
        state.isCreating = ApiLoadingStateEnum.loading;
      })
      .addCase(create.rejected, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        const bankDetails = action.payload as PaginatedBankDetailsDto;

        state.bankDetails = bankDetails as unknown as BankDetail[];
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindOne
      .addCase(findOne.fulfilled, (state, action) => {
        state.isFindOneLoading = ApiLoadingStateEnum.succeeded;
        state.bankDetail = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isFindOneLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isFindOneLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Update
      .addCase(update.fulfilled, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
        state.bankDetail = action.payload;
      })
      .addCase(update.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Remove
      .addCase(remove.fulfilled, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.succeeded;
        state.bankDetail = action.payload;
      })
      .addCase(remove.pending, (state) => {
        state.isRemoving = ApiLoadingStateEnum.loading;
      })
      .addCase(remove.rejected, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialBankDetailsState,
  name: 'bank-detail',
  reducers: {
    reset: () => {
      return {
        ...initialBankDetailsState,
      };
    },
    resetBankDetailState: (state) => {
      return {
        ...state,
        bankDetail: initialBankDetailsState.bankDetail,
      };
    },
    resetCreatingLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isCreating: ApiLoadingStateEnum.idle,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialBankDetailsState.errorResponse,
      };
    },
    resetLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isCreating: ApiLoadingStateEnum.idle,
        isFindOneLoading: ApiLoadingStateEnum.idle,
        isLoading: ApiLoadingStateEnum.idle,
        isRemoving: ApiLoadingStateEnum.idle,
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
    resetRemovingLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isRemoving: ApiLoadingStateEnum.idle,
      };
    },
    resetUpdatingLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
  },
});

const bankDetailsThunk = {
  create,
  findAll,
  findOne,
  remove,
  update,
};

const bankDetailsSelectors = {
  bankDetail: (state: RootState) => state.bankDetails.bankDetail,
  bankDetails: (state: RootState) => state.bankDetails.bankDetails,
  errorResponse: (state: RootState) => state.bankDetails.errorResponse,
  isCreating: (state: RootState) => state.bankDetails.isCreating,
  isFindOneLoading: (state: RootState) => state.bankDetails.isFindOneLoading,
  isLoading: (state: RootState) => state.bankDetails.isLoading,
  isRemoving: (state: RootState) => state.bankDetails.isRemoving,
  isUpdating: (state: RootState) => state.bankDetails.isUpdating,
};

export const bankDetailsService = {
  ...bankDetailsThunk,
  actions: bankDetailSlice.actions,
  selectors: bankDetailsSelectors,
};

export default bankDetailSlice.reducer;
