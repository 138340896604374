import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { ReactNode, useMemo } from 'react';
import { userService } from '../reducers/permissions/userSlice';
import { useTypedSelector } from '../stateStore';
import { AuthHelper } from '../utils/auth/authHelper';
import { isLoadingState } from '../utils/helpers/common';
import ErrorPage from './ErrorPage';
import { AppLoader } from './Loader';

interface IProtectElementProps {
  children: ReactNode;
  permissionRequired?: PermissionEnum[] | null;
  showErrorPage?: boolean;
  workspaceId?: string;
}

const ProtectElement = ({
  children,
  permissionRequired = [],
  showErrorPage = false,
  workspaceId,
}: IProtectElementProps) => {
  const userMe = useTypedSelector(userService.selectors.userMe);
  const isUserMeLoading = useTypedSelector(userService.selectors.isLoading);

  const isAuthorized = useMemo(() => {
    // Null means no user profile or permissions are required
    if (permissionRequired === null) return true;

    // User profile required
    if (!userMe) return false;

    // Empty array means no specific permission is required
    if (permissionRequired.length === 0) return true;

    const auth = new AuthHelper(userMe);

    if (workspaceId) {
      return auth.currentUserHasPermissionOnWorkspace(
        workspaceId,
        ...permissionRequired,
      );
    }

    return auth.currentUserHasPermission(...permissionRequired);
  }, [permissionRequired, userMe, workspaceId]);

  if (isAuthorized) return children;

  if (isLoadingState(isUserMeLoading))
    return <AppLoader.FlexLoader loaderHeight="h-screen" />;

  if (showErrorPage) return <ErrorPage notAuthorized />;

  return <> </>;
};

export default ProtectElement;
