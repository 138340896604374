import { withAuthenticationRequired } from '@auth0/auth0-react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { AppLoader } from '../components/Loader';
import ProtectElement from '../components/ProtectElement';
import { TProtectRouteLoaderData } from '../types/commonTypes';

interface IProtectRouteProps {
  children: ReactNode;
  isAuthenticated?: boolean;
}

const ProtectRoute = ({ children }: IProtectRouteProps) => {
  const loaderData = useLoaderData() as TProtectRouteLoaderData;
  const { workspaceId } = useParams();

  return (
    <ProtectElement
      permissionRequired={loaderData?.permissionRequired}
      showErrorPage
      workspaceId={workspaceId}
    >
      {children}
    </ProtectElement>
  );
};

const ProtectRouteRedirecting = () => (
  <div className="flex flex-col items-center justify-center text-center gap-8 p-10">
    <FontAwesomeIcon
      className="mb-5 text-read-only-bg"
      icon={faLock}
      size="7x"
    />
    <p className="text-base">Please wait while we redirect you to login...</p>
    <AppLoader.FlexLoader />
  </div>
);

export default withAuthenticationRequired(ProtectRoute, {
  onRedirecting: ProtectRouteRedirecting,
});
