import { User } from '@sr-sdks/api-sdk-axios';
import { ALL_WORKSPACES } from '../../constant/Common';
import { DeepPartial } from '../../types/commonTypes';

export class AuthHelper {
  constructor(private currentUser: DeepPartial<User>) {}

  static forUser(currentUser: DeepPartial<User>) {
    return new AuthHelper(currentUser);
  }

  currentUserHasPermission(...requiredPermissions: string[]): boolean {
    // Any workspace Ids returned means they have permission
    return this.getWorkspaceIds(...requiredPermissions).length > 0;
  }

  currentUserHasPermissionOnWorkspace(
    workspaceId: string,
    ...requiredPermissions: string[]
  ): boolean {
    const workspaceIds = this.getWorkspaceIds(...requiredPermissions);

    // Internal users with wildcard access have permission to any workspace
    if (this.currentUserIsInternal && workspaceIds.includes(ALL_WORKSPACES))
      return true;

    // External users have permission only if the requested Id is in the list
    return workspaceIds.includes(workspaceId);
  }

  get currentUserIsExternal() {
    return !this.currentUserIsInternal;
  }

  get currentUserIsInternal() {
    return this.currentUser.userType === 'internal';
  }

  getWorkspaceIds(...requiredPermissions: string[]) {
    let userRoles = this.currentUser?.roles || [];

    if (requiredPermissions.length > 0) {
      // Filter to the userRoles which have the required permission
      userRoles = userRoles.filter((userRole) =>
        userRole?.role?.permissions?.some((p) =>
          requiredPermissions.includes(p?.key as string),
        ),
      );
    }

    return userRoles.map((ur) => ur?.workspaceId);
  }
}
