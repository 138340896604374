import React, { LazyExoticComponent, Suspense } from 'react';
import { AppLoader } from '../components/Loader';
import ProtectRoute from './ProtectRoute';

/**
 * Usage:
 * element: LazyLoadElement(React.lazy(() => import('./components/MyComponent'))
 */
export function LazyLoadElement(LazyElement: LazyExoticComponent<any>) {
  // Wrapping around the suspense component is mandatory
  return (
    <Suspense fallback={<AppLoader.FlexLoader loaderHeight="h-screen" />}>
      <LazyElement />
    </Suspense>
  );
}

export function ProtectLazyLoadRoute(LazyElement: LazyExoticComponent<any>) {
  // Wrapping around the suspense component is mandatory
  return (
    <ProtectRoute>
      <Suspense fallback={<AppLoader.FlexLoader loaderHeight="h-screen" />}>
        <LazyElement />
      </Suspense>
    </ProtectRoute>
  );
}
