/* eslint-disable perfectionist/sort-objects */

// Define configuration variables to be used in the React app
// -- no secrets here --
type BuildEnv = 'development' | 'local' | 'production' | 'staging';
export const environmentName = (process.env.BUILD_ENV ||
  'development') as BuildEnv;

export const packageVersion = process.env.PACKAGE_VERSION;

// Default environment variables for BUILD_ENV=development
export const configuration = {
  AUTH0_AUDIENCE: `https://*.${environmentName}.backend.screenrights.app`,
  AUTH0_CLIENTID: 'fuPwUH3qeDhR3GLTqAOeteSUQVZMfzgP',
  AUTH0_DOMAIN: 'dev-p3p4mf8f.us.auth0.com',
  AUTH0_DOMAIN_URL: '', // Populated dynamically below
  DOCUMENTS_S3_BUCKET: 'screenrights-documents-service-storage-dev',
  GA_MEASUREMENT_ID: 'G-TRDD4YRGKV',
  GOOGLE_PLACES_API_KEY: 'AIzaSyCkLxd3vLaMpjrTs1cfbUzCNyT4jgi6bf8',
  MYSCREENRIGHTS_BASE: 'https://localhost:5001',
  SENTRY_DSN: process.env.SENTRY_DSN || '',
  // API base urls
  DOCUMENTS_API_BASE: `https://gateway.${environmentName}.backend.screenrights.app/documents`,
  PAYMENTS_API_BASE: `https://payments.${environmentName}.backend.screenrights.app`,
  PERMISSIONS_API_BASE: `https://permissions.${environmentName}.backend.screenrights.app`,
  SERVICES_API_BASE: `https://gateway.${environmentName}.backend.screenrights.app/services`,
  SHARED_API_BASE: `https://shared.${environmentName}.backend.screenrights.app`,
  TITLES_API_BASE: `https://titles.${environmentName}.backend.screenrights.app`,
  WORKSPACES_API_BASE: `https://workspaces.${environmentName}.backend.screenrights.app`,
  // Audit & Reconciliation reports
  RECONCILIATION_REPORT_URL: `https://redash.screenrights.org/dashboard/${environmentName}---pre---reconciliation-reports`,
  AUDIT_REPORT_URL: `https://redash.screenrights.org/dashboard/${environmentName}---pre---audit-trail`,
  HIDE_CHATBOT: true,
};

// Overrides for specific BUILD_ENVs
switch (environmentName) {
  case 'local':
    Object.assign(configuration, {
      // Local still uses the development auth0 audience
      AUTH0_AUDIENCE: 'https://*.development.backend.screenrights.app',
      // API base urls
      DOCUMENTS_API_BASE: 'http://localhost:3005',
      PAYMENTS_API_BASE: 'http://localhost:3003',
      PERMISSIONS_API_BASE: 'http://localhost:3001',
      SERVICES_API_BASE: 'http://localhost:3006',
      SHARED_API_BASE: 'http://localhost:2999',
      TITLES_API_BASE: 'http://localhost:3004',
      WORKSPACES_API_BASE: 'http://localhost:3002',
      // Audit & Reconciliation reports
      RECONCILIATION_REPORT_URL: `https://redash.screenrights.org`,
      AUDIT_REPORT_URL: `https://redash.screenrights.org`,
    });
    break;

  case 'staging':
    Object.assign(configuration, {
      AUTH0_CLIENTID: '2vFd6o9VgPGlVfYVwixIImHt3kSvNDoY',
      AUTH0_DOMAIN: 'auth.staging.screenrights.app',
      GA_MEASUREMENT_ID: 'G-VZKH9P32YD',
      MYSCREENRIGHTS_BASE: 'https://testmy.screenrights.org',
      HIDE_CHATBOT: false,
    });
    break;

  case 'production':
    Object.assign(configuration, {
      AUTH0_CLIENTID: 'nHv9WDWJH1PysREAcjpHptL0cCnlXj5l',
      AUTH0_DOMAIN: 'auth.screenrights.app',
      DOCUMENTS_S3_BUCKET: 'screenrights-documents-service-storage',
      GA_MEASUREMENT_ID: 'G-ZJYDRL4FM3',
      GOOGLE_PLACES_API_KEY: 'AIzaSyB1kT2rFdCraTlovE8dukfJucG0sUkXBN4',
      MYSCREENRIGHTS_BASE: 'https://my.screenrights.org',
      HIDE_CHATBOT: false,
      SENTRY_DSN:
        'https://fd399a8b709846b2b69e2665b670c56b@o166549.ingest.sentry.io/4505549229064192',
    });
    break;
}

configuration['AUTH0_DOMAIN_URL'] = 'https://' + configuration.AUTH0_DOMAIN;
