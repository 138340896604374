import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  SettingsApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { SettingSliceState } from './settingSliceState';

// Function for creating an instance of the InvitationsApi class
const GetSettingsApi = () => {
  return new SettingsApi(new Configuration(), configuration.SHARED_API_BASE);
};

const findAllConfiguration = createAsyncThunk(
  'setting/findAllConfiguration',
  async (_, thunkApi) => {
    try {
      const result =
        await GetSettingsApi().settingControllerFindAllConfiguration();

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAllFeatures = createAsyncThunk(
  'setting/findAllFeatures',
  async (_, thunkApi) => {
    try {
      const result = await GetSettingsApi().settingControllerFindAllFeatures();

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialSettingState: SettingSliceState = {
  configuration: {},
  errorResponse: undefined,
  features: {},
};

const settingsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAllConfiguration
      .addCase(findAllConfiguration.fulfilled, (state, action) => {
        state.configuration = action.payload as any;
      })

      // FindAllFeatures
      .addCase(findAllFeatures.fulfilled, (state, action) => {
        state.features = action.payload as any;
      })

      .addMatcher(
        isAnyOf(findAllConfiguration.rejected, findAllFeatures.rejected),
        (state, action) => {
          state.errorResponse = action.payload as ErrorResponse;
        },
      );
  },
  initialState: initialSettingState,
  name: 'settings',
  reducers: {
    reset: () => {
      return {
        ...initialSettingState,
      };
    },
  },
});

const settingThunk = {
  findAllFeatures,
};

const settingSelectors = {
  configuration: (state: RootState) => state.settings.configuration,
  error: (state: RootState) => state.settings.errorResponse,
  features: (state: RootState) => state.settings.features,
};

export const settingService = {
  ...settingThunk,
  actions: settingsSlice.actions,
  selectors: settingSelectors,
};

export default settingsSlice.reducer;
