import { autoUpdate, useFloating } from '@floating-ui/react-dom';
import clsx from 'clsx';
import React, { ReactNode, useRef, useState } from 'react';
import { Button } from 'react-daisyui';
import { twMerge } from 'tailwind-merge';
import './AppDropdown.css';

export interface IAppDropdownProps {
  buttonClassName: string;
  children: ReactNode;
  error?: boolean;
  isReadOnly?: boolean;
  menuType?: boolean;
  toggleContent: ReactNode;
  toggleIconElement?: ReactNode;
}

const AppDropdown = ({
  buttonClassName,
  children,
  error = false,
  isReadOnly = false,
  menuType,
  toggleContent,
  toggleIconElement,
}: IAppDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout>();
  const buttonClasses = twMerge(
    'whitespace-nowrap w-max p-[4px]',
    buttonClassName,
  );

  const location = menuType ? 'bottom-start' : 'bottom-end';

  const { floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: location,
    strategy: 'fixed',
    transform: false,
    whileElementsMounted: autoUpdate,
  });

  const handleMouseLeave = () => {
    if (!isOpen) return;

    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = undefined;
    }, 500);
  };

  const handleMouseEnter = () => {
    if (!closeTimeoutRef.current) return;

    clearTimeout(closeTimeoutRef.current);
    closeTimeoutRef.current = undefined;
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Dropdown */}
      <div
        className={twMerge(
          'dropdown static',
          clsx({
            '!relative': menuType,
            'dropdown-open': isOpen,
            'read-only': isReadOnly,
          }),
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Dropdown.Toggle */}
        <Button
          className={twMerge(
            buttonClasses,
            clsx({
              '!bg-read-only-bg': isReadOnly,
              '!bg-sr-input-error': error,
            }),
          )}
          disabled={isReadOnly}
          endIcon={toggleIconElement}
          ref={refs.setReference}
          type="button"
          onClick={handleClick}
        >
          {toggleContent}
        </Button>
        {/* Dropdown.Menu */}
        {isOpen && (
          <ul
            className="p-2 z-30 shadow dropdown-content menu menu-compact !bg-sr-white rounded-2xl"
            ref={refs.setFloating}
            style={menuType ? { width: '99%' } : floatingStyles}
            onClick={handleClick}
          >
            {children}
          </ul>
        )}
      </div>
    </>
  );
};

export default AppDropdown;
