import { faker } from '@faker-js/faker';
import { PaginationMeta } from '@sr-sdks/api-sdk-axios';
import {
  ActivityStatement,
  ActivityStatementEnum,
} from '../../types/workspaceTypes';

const createRandomActivityStatements = (): ActivityStatement => {
  return {
    _id: faker.string.uuid(),
    cascadeDate: faker.date.past().toISOString(),
    currency: faker.finance.currency().code,
    disbursementAmount: faker.finance.amount({
      autoFormat: true,
      dec: 2,
      max: 5000,
      min: 1000,
      symbol: '',
    }),
    status: faker.helpers.enumValue(ActivityStatementEnum),
    title: faker.music.songName(),
  };
};

export const mockedActivityStatementsPagination = {
  currentPage: 1,
  itemCount: 10,
  itemsPerPage: 10,
  totalItems: 10,
  totalPages: 1,
} as PaginationMeta;

export const mockedActivityStatements = faker.helpers.multiple(
  createRandomActivityStatements,
  {
    count: 10,
  },
);
