import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultLayoutV3 from './DefaultLayoutV3';

interface IDashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayout = ({ children }: IDashboardLayoutProps): JSX.Element => {
  return (
    <DefaultLayoutV3 mainWrapperBgColor="white">
      <DefaultLayoutV3.Body>{children ?? <Outlet />}</DefaultLayoutV3.Body>
    </DefaultLayoutV3>
  );
};

export default DashboardLayout;
