import React, { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import DashboardLayout from '../layout/DashboardLayout';
import RootLayout from '../layout/RootLayout';
import { AdminRoutes } from './AdminRoutes';
import { EmbedRoutes } from './EmbedRoutes';
import { LazyLoadElement, ProtectLazyLoadRoute } from './LazyLoadElement';
import { OnboardingRoutes } from './OnboardingRoutes';
import { ProfileRoutes } from './ProfileRoutes';
import { StaffRoutes } from './StaffRoutes';
import { WorkspaceRoutes } from './WorkspaceRoutes';

export const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Navigate replace to="/dashboard" />,
      },
      {
        path: 'admin',
        children: AdminRoutes,
      },
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          {
            path: '',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Dashboard/Dashboard')),
            ),
          },
        ],
      },
      {
        path: 'invitation',
        element: LazyLoadElement(
          lazy(() => import('../pages/Invitation/Invitation')),
        ),
      },
      {
        path: 'login',
        element: <Navigate replace to="/dashboard" />,
      },
      {
        path: 'login-callback',
        element: LazyLoadElement(
          lazy(() => import('../components/auth/LoginCallback')),
        ),
      },
      {
        path: 'logout',
        element: LazyLoadElement(
          lazy(() => import('../components/auth/LogoutCallback')),
        ),
      },
      {
        path: 'logged-out',
        element: <DashboardLayout />,
        children: [
          {
            path: '',
            element: LazyLoadElement(
              lazy(() => import('../components/auth/LoggedOut')),
            ),
          },
        ],
      },
      {
        path: 'onboarding/:workspaceId',
        children: OnboardingRoutes,
      },
      {
        path: 'profile',
        children: ProfileRoutes,
      },
      {
        path: 'workspace/:workspaceId',
        children: WorkspaceRoutes,
      },
      {
        path: 'embed/:workspaceId',
        children: EmbedRoutes,
      },
      {
        path: 'staff',
        children: StaffRoutes,
      },
      {
        path: 'terms',
        element: LazyLoadElement(
          lazy(() => import('../pages/TermsAndConditions/TermsAndConditions')),
        ),
      },
      {
        path: '*',
        element: (
          <DashboardLayout>
            <ErrorPage notFound />
          </DashboardLayout>
        ),
      },
    ],
    errorElement: (
      <DashboardLayout>
        <ErrorPage genericError />
      </DashboardLayout>
    ),
  },
]);
