import { User } from '@sr-sdks/api-sdk-axios';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { configuration } from '../configuration';
import { userService } from '../reducers/permissions/userSlice';
import { useTypedSelector } from '../stateStore';
import { AuthHelper } from '../utils/auth/authHelper';

const handleHubspotChat = (currentUser: User | undefined) => {
  // Selectively load the chat widget depending on configuration and user
  // E.g.: We generally don't want the chat widget shown on non-prod environments or to staff
  if (
    !configuration.HIDE_CHATBOT &&
    currentUser &&
    AuthHelper.forUser(currentUser).currentUserIsExternal
  ) {
    // Load the widget
    window.HubSpotConversations.widget.load();
  }
};

const RootLayout = (): JSX.Element => {
  const currentUser = useTypedSelector(userService.selectors.userMe);

  useEffect(() => {
    // Disable immediate loading of the chat widget so that we can selectively load in the handler later
    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    // Configure the conversations widget when it is ready
    // If external API methods are already available, use them.
    // See https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
    if (window.HubSpotConversations) {
      handleHubspotChat(currentUser);
    } else {
      // Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      // These callbacks will be called once the external API has been initialized.
      window.hsConversationsOnReady = [() => handleHubspotChat(currentUser)];
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        {/* Load the Hubspot script into the head section */}
        <script
          id="hs-script-loader"
          async
          defer
          src="https://js-na1.hs-scripts.com/6938094.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <Outlet />
    </>
  );
};

export default RootLayout;
