import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  ServiceAccessEnum,
  ServiceAccessRolesApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { ServiceSliceState } from './servicesState';

// Function for creating an instance of the UsersApi class
const GetServicesApi = () => {
  return new ServiceAccessRolesApi(
    new Configuration(),
    configuration.SERVICES_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk(
  'service-access-role/findAll',
  async (
    {
      serviceKey,
      workspaceId,
    }: {
      serviceKey: ServiceAccessEnum;
      workspaceId: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetServicesApi().serviceAccessRoleControllerFindAll(
        serviceKey,
        workspaceId,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialServiceAccessRoleState: ServiceSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  serviceAccessRole: [],
};

const serviceAccessRoleSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.serviceAccessRole = action.payload;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialServiceAccessRoleState,
  name: 'service-access-role',
  reducers: {
    reset: () => {
      return {
        ...initialServiceAccessRoleState,
      };
    },
  },
});

const serviceAccessRoleThunk = {
  findAll,
};

const serviceAccessRoleSelectors = {
  errorResponse: (state: RootState) => state.serviceAccessRole.errorResponse,
  isLoading: (state: RootState) => state.serviceAccessRole.isLoading,
  serviceAccessRole: (state: RootState) =>
    state.serviceAccessRole.serviceAccessRole,
};

export const accessRoleService = {
  ...serviceAccessRoleThunk,
  actions: serviceAccessRoleSlice.actions,
  selectors: serviceAccessRoleSelectors,
};

export default serviceAccessRoleSlice.reducer;
