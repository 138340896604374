import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const ProfileRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(
      lazy(() => import('../layout/ProfileLayout')),
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to="my-profile" />,
      },
      {
        path: 'my-profile',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Profile/MyProfile')),
        ),
        loader: () => ({
          title: 'My Profile',
        }),
      },
      {
        path: 'notifications',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Profile/Notifications')),
        ),
        loader: () => ({
          permissionRequired: [PermissionEnum.ViewNotificationFeed],
          title: 'Notifications',
        }),
      },
    ],
  },
];
