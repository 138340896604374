import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const OnboardingRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(
      lazy(() => import('../layout/OnboardingLayout')),
    ),
    loader: () => {
      return {
        // Passing null allows access even when they don't have a profile or any roles
        // But still requires an auth0 session
        permissionRequired: null,
      };
    },
    children: [
      {
        children: [
          {
            path: 'create-profile',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Onboarding/CreateProfile')),
            ),
            loader: () => {
              return {
                permissionRequired: null,
              };
            },
          },
          {
            path: 'setup-workspace',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Onboarding/SetupWorkspaceV2')),
            ),
            loader: () => {
              return {
                permissionRequired: [PermissionEnum.EditWorkspaceDetails],
              };
            },
          },
          {
            path: 'bank-details',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Onboarding/BankDetailsV3')),
            ),
            loader: () => {
              return {
                permissionRequired: [PermissionEnum.EditBankDetails],
              };
            },
          },
          {
            path: 'confirm-clients',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Onboarding/SetupConfirmClients')),
            ),
            loader: () => ({
              title: 'Confirm Clients',
            }),
          },
          {
            path: 'review-clients',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Onboarding/SetupReviewClients')),
            ),
            loader: () => ({
              title: 'Review Clients',
            }),
          },
        ],
        errorElement: <ErrorPage genericError />,
      },
    ],
  },
];
