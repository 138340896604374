import stateStore from '../stateStore';
import { agreementService } from './CollectionAccounts/agreementsSlice';
import { saleService } from './CollectionAccounts/saleSlice';
import { globalService } from './globalState/globalStateSlice';
import { bankDetailsService } from './payments/bankDetailSlice';
import { invoiceService } from './payments/invoiceSlice';
import { invitationService } from './permissions/invitationSlice';
import { userRoleService } from './permissions/userRoleSlice';
import { userService } from './permissions/userSlice';
import { notificationService } from './shared/notificationSlice';
import { settingService } from './shared/settingSlice';
import { titleService } from './titles/titleSlice';
import { clientService } from './workspaces/clientSlice';
import { workspaceService } from './workspaces/workspaceSlice';

export const reduxServices = {
  agreement: agreementService,
  bankDetails: bankDetailsService,
  client: clientService,
  globalState: globalService,
  invitation: invitationService,
  invoice: invoiceService,
  notification: notificationService,
  resetAppState: () => {
    return Promise.all([
      stateStore.dispatch(userService.actions.reset()),
      stateStore.dispatch(workspaceService.actions.reset()),
      stateStore.dispatch(bankDetailsService.actions.reset()),
    ]);
  },
  sale: saleService,
  setCurrentError: (error: any) => {
    return stateStore.dispatch(
      reduxServices.globalState.actions.setCurrentError(error),
    );
  },
  setting: settingService,
  showErrorModal: (isOpen: boolean) => {
    return stateStore.dispatch(
      reduxServices.globalState.actions.setShowErrorModal(isOpen),
    );
  },
  showErrorPage: (isOpen: boolean) => {
    return stateStore.dispatch(
      reduxServices.globalState.actions.setShowErrorPage(isOpen),
    );
  },
  title: titleService,
  user: userService,
  userRole: userRoleService,
  workspace: workspaceService,
};
