import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Tooltip } from 'react-daisyui';

export interface IIconButtonProps {
  className?: string;
  icon: IconDefinition;
  iconClassName?: string;
  iconColor?:
    | 'accent'
    | 'error'
    | 'ghost'
    | 'info'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  iconSize?: '1x' | '2x';
  label: string;
  onClick?: () => void;
  tipPosition?: 'bottom' | 'left' | 'right' | 'top' | undefined;
}

const IconButton = ({
  className,
  icon,
  iconClassName,
  iconColor = 'ghost',
  iconSize = '2x',
  label,
  onClick,
  tipPosition = 'top',
}: IIconButtonProps) => {
  return (
    <Tooltip message={label} position={tipPosition}>
      <Button
        className={className}
        color={iconColor}
        shape="circle"
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <FontAwesomeIcon
          className={iconClassName ? iconClassName : ''}
          icon={icon}
          size={iconSize}
        />
      </Button>
    </Tooltip>
  );
};

export default IconButton;
