import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  InvitationTemplateEnum,
  NotificationSubjectTypeEnum,
  NotificationsApi,
  PaginatedNotificationsDto,
  UpdateNotificationDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { NotificationSliceState } from './notificationSliceState';

const GetNotificationsApi = () => {
  return new NotificationsApi(
    new Configuration(),
    configuration.SHARED_API_BASE,
    axiosInstance,
  );
};

const findAllPaginated = createAsyncThunk(
  'notification/findAllPaginated',
  async (
    {
      page = 1,
      pageSize = 10,
      sortBy = 'created:desc',
      subjectType,
      userId,
      workspaceId,
    }: {
      page?: number;
      pageSize?: number;
      sortBy?: string;
      subjectType?: NotificationSubjectTypeEnum;
      userId?: string;
      workspaceId?: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetNotificationsApi().notificationControllerFindAll(
        subjectType,
        userId,
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const previewInvitation = createAsyncThunk(
  'notification/previewInvitation',
  async (
    {
      invitationTemplate,
      inviter,
      title,
      workspaceName,
    }: {
      invitationTemplate: InvitationTemplateEnum;
      inviter: string;
      title?: string;
      workspaceName?: string;
    },
    thunkApi,
  ) => {
    try {
      const result =
        await GetNotificationsApi().notificationControllerPreviewInvitation(
          invitationTemplate,
          inviter,
          title,
          workspaceName,
        );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'notification/update',
  async (
    {
      id,
      updateNotificationDto,
    }: { id: string; updateNotificationDto: UpdateNotificationDto },
    thunkApi,
  ) => {
    try {
      const result = await GetNotificationsApi().notificationControllerUpdate(
        id,
        updateNotificationDto,
        invalidateCacheRequestConfig('notification'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialNotificationState: NotificationSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  isNotificationLoading: ApiLoadingStateEnum.idle,
  isNotificationUpdating: ApiLoadingStateEnum.idle,
  notifications: [],
};

const notificationsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(previewInvitation.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.emailPreviewHtml = action.payload as any;
      })
      .addCase(previewInvitation.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(previewInvitation.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Update
      .addCase(update.fulfilled, (state, action) => {
        state.isNotificationUpdating = ApiLoadingStateEnum.succeeded;
        state.notification = action.payload;
      })
      .addCase(update.pending, (state) => {
        state.isNotificationUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isNotificationUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAllPaginated
      .addCase(findAllPaginated.fulfilled, (state, action) => {
        state.isNotificationLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as PaginatedNotificationsDto;

        state.notifications = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAllPaginated.pending, (state) => {
        state.isNotificationLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllPaginated.rejected, (state, action) => {
        state.isNotificationLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialNotificationState,
  name: 'notifications',
  reducers: {},
});

const notificationThunk = {
  findAllPaginated,
  previewInvitation,
  update,
};

const notificationSelectors = {
  emailPreviewHtml: (state: RootState) => state.notifications.emailPreviewHtml,
  errorResponse: (state: RootState) => state.notifications.errorResponse,
  isLoading: (state: RootState) => state.notifications.isLoading,
  isNotificationLoading: (state: RootState) =>
    state.notifications.isNotificationLoading,
  isNotificationUpdating: (state: RootState) =>
    state.notifications.isNotificationUpdating,
  notification: (state: RootState) => state.notifications.notification,
  notifications: (state: RootState) => state.notifications.notifications,
  paginationMeta: (state: RootState) => state.notifications.paginationMeta,
};

export const notificationService = {
  ...notificationThunk,
  actions: notificationsSlice.actions,
  selectors: notificationSelectors,
};

export default notificationsSlice.reducer;
