import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { configuration } from '../../configuration';
import { userService } from '../../reducers/permissions/userSlice';
import { useAppDispatch } from '../../stateStore';

interface IAuth0ProviderWithRedirectProps {
  children?: ReactNode;
}

const Auth0ProviderWithRedirect = ({
  children,
}: IAuth0ProviderWithRedirectProps) => {
  const dispatch = useAppDispatch();

  const audience = configuration.AUTH0_AUDIENCE;
  const clientId = configuration.AUTH0_CLIENTID;
  const domain = configuration.AUTH0_DOMAIN;

  const handleRedirectCallback = (appState?: AppState) => {
    // When the appState contains a returnTo location, we want to redirect back there once the user is returned from Auth0 flow
    // Don't do the redirect now, but set returnLocation in redux which will be checked in LoginCallback
    dispatch(userService.actions.setReturnLocation(appState?.returnTo));
  };

  // Configure the Auth0Provider component
  // Under the hood, the Auth0 React SDK uses React Context to manage the authentication state of your users.
  // One way to integrate Auth0 with your React app is to wrap your root component with an Auth0Provider that you can
  // Import from the SDK.
  //
  // Domain, clientId, and audience settings read from environment.
  // See https://create-react-app.dev/docs/adding-custom-environment-variables/#adding-development-environment-variables-in-env
  return (
    <Auth0Provider
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin + '/login-callback',
      }}
      clientId={clientId}
      domain={domain}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirect;
