import { configuration } from '../../configuration';
import { StringMap } from '../../types/commonTypes';

export const ApiList: StringMap<string> = {
  documents: configuration.DOCUMENTS_API_BASE,
  payments: configuration.PAYMENTS_API_BASE,
  permissions: configuration.PERMISSIONS_API_BASE,
  services: configuration.SERVICES_API_BASE,
  shared: configuration.SHARED_API_BASE,
  titles: configuration.TITLES_API_BASE,
  workspaces: configuration.WORKSPACES_API_BASE,
};
