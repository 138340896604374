import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const StaffRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(lazy(() => import('../layout/StaffLayout'))),
    loader: () => ({
      permissionRequired: [PermissionEnum.ViewStaffHomepage],
    }),
    children: [
      {
        index: true,
        element: <Navigate replace to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Staff/Dashboard')),
        ),
      },
      {
        path: 'workspaces',
        loader: () => ({
          title: 'Workspaces',
        }),
        children: [
          {
            index: true,
            element: <Navigate replace to="manage-workspaces" />,
          },
          {
            path: 'invitations',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Staff/Workspaces/Invitations')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewOnboarding],
              title: 'Invitations',
            }),
          },
          {
            path: 'invitations/residuals-onboarding',
            element: ProtectLazyLoadRoute(
              lazy(
                () => import('../pages/Staff/Workspaces/ResidualsOnboarding'),
              ),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewOnboarding],
              title: 'Invitations',
            }),
          },
          {
            path: 'manage-workspaces',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Staff/Workspaces/ManageWorkspaces')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewManageWorkspace],
              title: 'Manage Workspaces',
            }),
          },
          {
            path: 'manage-workspaces/:workspaceId',
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewManageWorkspace],
              title: 'Workspace Summary',
            }),
            children: [
              {
                index: true,
                element: ProtectLazyLoadRoute(
                  lazy(
                    () => import('../pages/Staff/Workspaces/WorkspaceDetails'),
                  ),
                ),
              },
              {
                path: 'summary/:titleId',
                element: ProtectLazyLoadRoute(
                  lazy(
                    () =>
                      import(
                        '../pages/Staff/Workspaces/WorkspaceTittleSummary'
                      ),
                  ),
                ),
                loader: () => ({
                  permissionRequired: [PermissionEnum.ViewManageWorkspace],
                  title: 'Title Summary',
                }),
                children: [
                  {
                    index: true,
                    element: <Navigate replace to="activity-statements" />,
                  },
                  {
                    path: 'activity-statements',
                    element: ProtectLazyLoadRoute(
                      lazy(
                        () =>
                          import('../pages/Workspace/Title/ActivityStatement'),
                      ),
                    ),
                    loader: () => ({
                      permissionRequired: [PermissionEnum.ViewCascades],
                      title: 'Title Summary',
                    }),
                  },
                  {
                    path: 'marketing-agreements',
                    element: ProtectLazyLoadRoute(
                      lazy(
                        () =>
                          import(
                            '../pages/Workspace/Title/MarketingAgreements'
                          ),
                      ),
                    ),
                    loader: () => ({
                      permissionRequired: [PermissionEnum.ViewCascades],
                      title: 'Title Summary',
                    }),
                  },
                  {
                    path: 'sales-reports',
                    element: ProtectLazyLoadRoute(
                      lazy(
                        () => import('../pages/Workspace/Title/SalesReport'),
                      ),
                    ),
                    loader: () => ({
                      permissionRequired: [PermissionEnum.ViewCascades],
                      title: 'Title Summary',
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        loader: () => ({
          title: 'Admin',
        }),
        children: [
          {
            index: true,
            element: <Navigate replace to="staff-access" />,
          },
          {
            path: 'staff-access',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Staff/Admin/StaffAccess')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditPermissions],
              title: 'Staff Access',
            }),
          },
        ],
      },
      {
        path: 'accounts',
        loader: () => ({
          title: 'Accounts',
        }),
        children: [
          {
            index: true,
            element: <Navigate replace to="manage-payouts" />,
          },
          {
            path: 'manage-payouts',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Staff/Accounts/ManagePayouts')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewPayouts],
              title: 'Manage Payouts',
            }),
          },
        ],
      },
    ],
  },
];
