import { createSlice } from '@reduxjs/toolkit';
import { PaginationMeta } from '@sr-sdks/api-sdk-axios';
import { RootState } from '../../stateStore';
import { mockedActivityStatements } from '../../test/workspace/mock-activity-statement';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import { ActivityStatementSliceState } from './collectionAccountState';

const initialActivityStatementsState: ActivityStatementSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  paginationMeta: {
    currentPage: 1,
    itemCount: 10,
    itemsPerPage: 10,
    totalItems: 10,
    totalPages: 1,
  } as PaginationMeta,
  statements: mockedActivityStatements,
};

const activityStatementSlice = createSlice({
  initialState: initialActivityStatementsState,
  name: 'activity-statements',
  reducers: {},
});

const activityStatementThunk = {};

const activityStatementSelectors = {
  errorResponse: (state: RootState) => state.activityStatements.errorResponse,
  isLoading: (state: RootState) => state.activityStatements.isLoading,
  paginationMeta: (state: RootState) => state.activityStatements.paginationMeta,
  statements: (state: RootState) => state.activityStatements.statements,
};

export const activityStatementService = {
  ...activityStatementThunk,
  actions: activityStatementSlice.actions,
  selectors: activityStatementSelectors,
};

export default activityStatementSlice.reducer;
