import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  Configuration,
  CreateProfileDto,
  ErrorResponse,
  PaginatedUsersDto,
  UpdateUserDto,
  UsersApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { CacheRequestConfig } from 'axios-cache-interceptor';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { ChangePasswordDto } from './changePasswordDto';
import { UserSliceState } from './userSliceState';

// Function for creating an instance of the UsersApi class
const GetUsersApi = () => {
  return new UsersApi(
    new Configuration(),
    configuration.PERMISSIONS_API_BASE,
    axiosInstance,
  );
};

// Function for creating an instance of the Axios
const UseAuth0Api = (requestConfig: CacheRequestConfig = {}) => {
  requestConfig.baseURL = configuration.AUTH0_DOMAIN_URL;

  // No cache for Auth0 requests
  requestConfig.cache = false;

  return axiosInstance(requestConfig);
};

const findAll = createAsyncThunk(
  'user/findAll',
  async (
    {
      page = 1,
      pageSize = 5,
      sortBy = 'firstName:asc,lastName:asc',
      workspaceId,
    }: {
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceId: string;
    },
    thunkApi,
  ) => {
    try {
      const response = await GetUsersApi().usersControllerFindAllV2(
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findMe = createAsyncThunk('user/findMe', async (_, thunkApi) => {
  try {
    const result = await GetUsersApi().usersControllerFindMe();

    return result.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      (error as AxiosError).response?.data as ErrorResponse,
    );
  }
});

const findOne = createAsyncThunk(
  'user/findOne',
  async (id: string, thunkApi) => {
    try {
      const result = await GetUsersApi().usersControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const updateMe = createAsyncThunk(
  'user/updateMe',
  async (payload: CreateProfileDto, thunkApi) => {
    try {
      const result = await GetUsersApi().usersControllerCreateMe(
        payload,
        invalidateCacheRequestConfig('user/me'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ErrorResponse);
    }
  },
);

const update = createAsyncThunk(
  'user/update',
  async (
    { id, updateUserDto }: { id: string; updateUserDto: UpdateUserDto },
    thunkApi,
  ) => {
    try {
      const result = await GetUsersApi().usersControllerUpdate(
        id,
        updateUserDto,
        invalidateCacheRequestConfig('user'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const changePassword = createAsyncThunk(
  'user/changePassword',
  async (emailAddress: string, thunkApi) => {
    const changePasswordDto: ChangePasswordDto = {
      client_id: configuration.AUTH0_CLIENTID,
      connection: 'Username-Password-Authentication',
      email: emailAddress,
    };

    try {
      const response = await UseAuth0Api({
        data: changePasswordDto,
        method: 'post',
        url: '/dbconnections/change_password',
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialUserState: UserSliceState = {
  isAuthenticated: false,
  isLoading: ApiLoadingStateEnum.idle,
  isPasswordChanging: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
  isUserLoading: ApiLoadingStateEnum.idle,
  isUserMeUpdating: ApiLoadingStateEnum.idle,
  isUsersLoading: ApiLoadingStateEnum.idle,
  users: [],
};

const usersSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(findAll.fulfilled, (state, action) => {
        state.isUsersLoading = ApiLoadingStateEnum.succeeded;

        const paginatedUser = action.payload as PaginatedUsersDto;

        state.users = paginatedUser.items;
        state.paginationMeta = paginatedUser.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isUsersLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isUsersLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      .addCase(findMe.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.userMe = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(findMe.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findMe.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      .addCase(updateMe.fulfilled, (state) => {
        state.isUserMeUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(updateMe.pending, (state) => {
        state.isUserMeUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.isUserMeUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isPasswordChanging = ApiLoadingStateEnum.succeeded;
      })
      .addCase(changePassword.pending, (state) => {
        state.isPasswordChanging = ApiLoadingStateEnum.loading;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isPasswordChanging = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      .addCase(findOne.fulfilled, (state, action) => {
        state.isUserLoading = ApiLoadingStateEnum.succeeded;
        state.user = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isUserLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isUserLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      .addMatcher(isAnyOf(updateMe.pending, update.pending), (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
        state.errorResponse = undefined;
      })
      .addMatcher(isAnyOf(updateMe.fulfilled, update.fulfilled), (state) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addMatcher(
        isAnyOf(updateMe.rejected, update.rejected),
        (state, action) => {
          state.isUpdating = ApiLoadingStateEnum.failed;
          state.errorResponse = action.payload as ErrorResponse;
        },
      );
  },
  initialState: initialUserState,
  name: 'users',
  reducers: {
    reset: () => {
      return {
        ...initialUserState,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialUserState.errorResponse,
      };
    },
    resetLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isUpdating: ApiLoadingStateEnum.idle,
        isUserMeUpdating: ApiLoadingStateEnum.idle,
      };
    },
    resetPasswordLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isPasswordChanging: ApiLoadingStateEnum.idle,
      };
    },
    setReturnLocation: (state, action) => {
      return {
        ...state,
        returnLocation: action.payload,
      };
    },
    setUserMe: (state, action) => {
      state.isLoading = ApiLoadingStateEnum.succeeded;
      state.userMe = action.payload;
    },
  },
});

const userThunk = {
  changePassword: changePassword,
  findAll: findAll,
  findMe: findMe,
  findOne: findOne,
  update: update,
  updateMe: updateMe,
};

const userSelectors = {
  errorResponse: (state: RootState) => state.users.errorResponse,
  isAuthenticated: (state: RootState) => state.users.isAuthenticated,
  isLoading: (state: RootState) => state.users.isLoading,
  isPasswordChanging: (state: RootState) => state.users.isPasswordChanging,
  isUpdating: (state: RootState) => state.users.isUpdating,
  isUserLoading: (state: RootState) => state.users.isUserLoading,
  isUserMeUpdating: (state: RootState) => state.users.isUserMeUpdating,
  isUsersLoading: (state: RootState) => state.users.isUsersLoading,
  paginationMeta: (state: RootState) => state.users.paginationMeta,
  returnLocation: (state: RootState) => state.users.returnLocation,
  user: (state: RootState) => state.users.user,
  userMe: (state: RootState) => state.users.userMe,
  users: (state: RootState) => state.users.users,
};

export const userService = {
  ...userThunk,
  actions: usersSlice.actions,
  selectors: userSelectors,
};

export default usersSlice.reducer;
