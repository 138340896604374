import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AgreementTypeEnum,
  AgreementsApi,
  Configuration,
  ErrorResponse,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { AgreementSliceState } from './agreementState';

const GetAgreementApi = () => {
  return new AgreementsApi(
    new Configuration(),
    configuration.SERVICES_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk(
  'agreement/findAll',
  async (
    {
      agreementType,
      beneficiaryTitleId,
      beneficiaryWorkspaceId,
      page,
      pageSize,
      sortBy,
    }: {
      agreementType?: AgreementTypeEnum;
      beneficiaryTitleId?: string;
      beneficiaryWorkspaceId: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetAgreementApi().agreementControllerFindAll(
        beneficiaryWorkspaceId,
        beneficiaryTitleId,
        agreementType,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const agreementState: AgreementSliceState = {
  agreements: [],
  isAgreementsLoading: ApiLoadingStateEnum.idle,
};

const agreementSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isAgreementsLoading = ApiLoadingStateEnum.succeeded;
        const paginatedResponse = action.payload;

        state.agreements = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isAgreementsLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isAgreementsLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: agreementState,
  name: 'agreement',
  reducers: {},
});

const agreementThunk = {
  findAll,
};

const agreementSelectors = {
  agreements: (state: RootState) => state.agreements.agreements,
  errorResponse: (state: RootState) => state.agreements.errorResponse,
  isAgreementsLoading: (state: RootState) =>
    state.agreements.isAgreementsLoading,
  paginationMeta: (state: RootState) => state.agreements.paginationMeta,
};

export const agreementService = {
  ...agreementThunk,
  actions: agreementSlice.actions,
  selectors: agreementSelectors,
};
export default agreementSlice.reducer;
