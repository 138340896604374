import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ClientRepresentationStatusEnum,
  ClientsApi,
  Configuration,
  ErrorResponse,
  PaginatedClientsDto,
  UpdateClientDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { ClientSliceState } from './clientSliceState';

// Function for creating an instance of the UsersApi class
const GetClientsApi = () => {
  return new ClientsApi(
    new Configuration(),
    configuration.WORKSPACES_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk(
  'client/findAll',
  async (workspaceId: string, thunkApi) => {
    try {
      const result = await GetClientsApi().clientControllerFindAll(workspaceId);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findOne = createAsyncThunk(
  'client/findOne',
  async (id: string, thunkApi) => {
    try {
      const result = await GetClientsApi().clientControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAllPaginated = createAsyncThunk(
  'client/findAllPaginated',
  async (
    {
      page = 1,
      pageSize = 10,
      representationStatus,
      sortBy,
      workspaceId,
    }: {
      page?: number;
      pageSize?: number;
      representationStatus?: ClientRepresentationStatusEnum;
      sortBy?: string;
      workspaceId: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetClientsApi().clientControllerFindAllV2(
        workspaceId,
        representationStatus,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'client/update',
  async (
    { id, updateClientDto }: { id: string; updateClientDto: UpdateClientDto },
    thunkApi,
  ) => {
    try {
      const result = await GetClientsApi().clientControllerUpdate(
        id,
        updateClientDto,
        invalidateCacheRequestConfig('client'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialClientState: ClientSliceState = {
  clients: [],
  isClientLoading: ApiLoadingStateEnum.idle,
  isClientUpdating: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
};

const clientsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindOne
      .addCase(findOne.fulfilled, (state, action) => {
        state.isClientLoading = ApiLoadingStateEnum.succeeded;
        state.client = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isClientLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isClientLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.clients = action.payload as any;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Update
      .addCase(update.fulfilled, (state, action) => {
        state.isClientUpdating = ApiLoadingStateEnum.succeeded;
        state.client = action.payload;
      })
      .addCase(update.pending, (state) => {
        state.isClientUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isClientUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAllPaginated
      .addCase(findAllPaginated.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as PaginatedClientsDto;

        state.clients = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAllPaginated.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllPaginated.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialClientState,
  name: 'client',
  reducers: {
    reset: () => {
      return {
        ...initialClientState,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialClientState.errorResponse,
      };
    },
    updateRepresentationStatus: (state, action) => {
      const { id, representationStatus } = action.payload;

      if (id) {
        const indexToUpdate = state.clients.findIndex(
          (client) => client._id === id,
        );

        if (indexToUpdate !== -1) {
          state.clients[indexToUpdate].representationStatus =
            representationStatus;
        }
      } else {
        const resetStatuses = state.clients.map((client) => ({
          ...client,
          representationStatus: representationStatus,
        }));

        state.clients = resetStatuses;
      }
    },
  },
});

const clientThunk = {
  findAll,
  findAllPaginated,
  findOne,
  update,
};

const clientSelectors = {
  client: (state: RootState) => state.clients.client,
  clients: (state: RootState) => state.clients.clients,
  errorResponse: (state: RootState) => state.clients.errorResponse,
  isClientLoading: (state: RootState) => state.clients.isClientLoading,
  isClientUpdating: (state: RootState) => state.clients.isClientUpdating,
  isLoading: (state: RootState) => state.clients.isLoading,
  paginationMeta: (state: RootState) => state.clients.paginationMeta,
};

export const clientService = {
  ...clientThunk,
  actions: clientsSlice.actions,
  selectors: clientSelectors,
};

export default clientsSlice.reducer;
