import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import App from './App';
import Auth0ProviderWithRedirect from './components/auth/Auth0ProviderWithRedirect';
import {
  configuration,
  environmentName,
  packageVersion,
} from './configuration';
import './index.css';
import reportWebVitals from './reportWebVitals';
import stateStore from './stateStore';
import { ApiList } from './utils/api/ApiList';

ReactGA.initialize(configuration.GA_MEASUREMENT_ID);

Sentry.init({
  dsn: configuration.SENTRY_DSN,
  environment: environmentName,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: Object.values(ApiList),
    }),
    new Sentry.Replay(),
  ],
  // Release configuration
  release: packageVersion,
  // Session Replay
  replaysOnErrorSampleRate: 1.0, // Set sample rate to 100% when sampling sessions where errors occur
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={stateStore}>
    <Auth0ProviderWithRedirect>
      <App />
    </Auth0ProviderWithRedirect>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
