import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Navbar } from 'react-daisyui';
import { Link, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import AppAvatar from '../../components/AppAvatar';
import { userService } from '../../reducers/permissions/userSlice';
import { workspaceService } from '../../reducers/workspaces/workspaceSlice';
import { useAppDispatch, useTypedSelector } from '../../stateStore';
import { AuthHelper } from '../../utils/auth/authHelper';
import { userInitials } from '../../utils/helpers/common';
import IfFeatureEnabled from '../IfFeatureEnabled';

export const WorkspaceSidebarIcons = () => {
  const dispatch = useAppDispatch();

  const currentUser = useTypedSelector(userService.selectors.userMe);

  const workspaces = useTypedSelector(workspaceService.selectors.workspaces);

  useEffect(() => {
    if (!currentUser) return;

    if (
      AuthHelper.forUser(currentUser).currentUserHasPermission(
        PermissionEnum.ViewWorkspace,
      )
    ) {
      dispatch(workspaceService.findAll());
    }
  }, [currentUser, dispatch]);

  if (workspaces?.length === 0) {
    return <></>;
  }

  return (
    <>
      <Navbar className="flex-col p-0">
        <Navbar.Start className="block w-full modal-avatar">
          {workspaces
            ?.slice(0, 5)
            .map(({ _id: workspaceId, imageUri, workspaceName }) => {
              return (
                <NavLink
                  key={workspaceId}
                  className={({ isActive }) => {
                    return twMerge(
                      'avatar w-full justify-center py-4 z-10',
                      clsx({ active: isActive }),
                    );
                  }}
                  to={`/workspace/${workspaceId}`}
                >
                  <AppAvatar
                    imgUri={imageUri}
                    letters={userInitials(workspaceName)}
                    shape="square"
                    size="sm"
                  />
                </NavLink>
              );
            })}
        </Navbar.Start>
        <Navbar.End className="w-full">
          <IfFeatureEnabled name="sidebarAddWorkspaceButton">
            <Link
              className="mt-10 border-2 border-sr-black hover:border-neutral hover:rounded-2xl p-2.5 mb-10"
              to="/workspace/add"
            >
              <FontAwesomeIcon icon={faAdd} size="2x" />
            </Link>
          </IfFeatureEnabled>
          <IfFeatureEnabled name="sidebarViewAllWorkspaces">
            {workspaces.length > 4 && (
              <Link
                className="pb-3.5 w-full absolute left-0 bottom-0 text-sm"
                to="/workspace"
              >
                View All
              </Link>
            )}
          </IfFeatureEnabled>
        </Navbar.End>
      </Navbar>
    </>
  );
};
